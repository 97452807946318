<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style src="./main.css"></style>
<style>
@media only screen and (max-width: 1200px) {
  .navlink {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media only screen and (min-width: 991px) {
  .dropme:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: #f3f7f5;
    border-top: 0;
    margin-left: -10px;
    margin-top: -10px;
  }
}
@media only screen and (max-width: 1260px) {
  .footer-link {
    margin-left: 0px;
    margin-right: 15px;
  }
  .footer-links-box {
    float: left;
    clear: both;
  }
}
@media only screen and (max-width: 1100px) {
  .inner-head {
    font-size: 45px;
  }
}
@media only screen and (max-width: 880px) {
  .inner-head {
    font-size: 38px;
  }
}
.main-sec {
  min-height: calc(100vh - 254px);
}
ul {
  list-style: none;
}
ul li::before {
  content: " ";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: -14px;
  transform: translate(-22px, 3px);
  border: 2px solid #e3ede9;
  background-color: #005c34;
  border-radius: 100%;
}
@media only screen and (max-width: 767px) {
  .sidetab {
    white-space: nowrap;
  }
}
.noscroll {
  margin: 0;
  height: 100%;
  overflow: hidden;
  position: fixed;
  width: 100%;
}
::-moz-selection {
  color: white;
  background: #005c34;
}
::selection {
  color: white;
  background: #005c34;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.enddots {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 15px;
}
</style>
