<template>
  <footer class="footer w-clearfix wf-section">
    <p class="copy">
      © <span id="currentYear">2023</span> Sanford T. Colb &amp; Co.
    </p>
    <div class="footer-links-box">
      <router-link
        to="/about"
        aria-current="page"
        class="footer-link w--current"
        >About</router-link
      ><router-link to="/practice-areas/anti-counterfeiting" class="footer-link"
        >Practice Areas</router-link
      ><router-link to="/professionals" class="footer-link"
        >Professionals</router-link
      ><router-link to="/charges-and-forms" class="footer-link"
        >Charges and forms</router-link
      ><router-link to="/contact" class="footer-link">Contact</router-link
      ><router-link to="/terms-of-use" class="footer-link"
        >Terms of use</router-link
      >
    </div>
  </footer>
</template>
<script>
export default {
  name: "botfooter",
};
</script>
