<template>
  <div class="body">
    <topnav></topnav>
    <div class="main-sec wf-section">
      <div class="_1200">
        <h1 class="inner-head">Practice Areas</h1>
        <div class="main-tabs w-clearfix">
          <div class="tabs-menu line">
            <router-link
              v-for="area in $local.practiceAreas"
              :key="area._id"
              :to="`/practice-areas/${area.slug}`"
              aria-current="page"
              class="sidetab w-inline-block"
              :class="{ 'w--current': $route.params.slug === area.slug }"
              ><div>{{ area.title }}</div></router-link
            >
          </div>
          <div class="tabs-content">
            <div
              class="long-txt long-rich w-richtext"
              v-html="service.body"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <botfooter></botfooter>
  </div>
</template>
<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
export default {
  components: {
    Topnav,
    Botfooter,
  },
  name: "Practice-areas-anti-counterfeiting",
  computed: {
    service() {
      const { slug } = this.$route.params;
      return this.$local.practiceAreas.find((s) => s.slug === slug);
    },
  },
};
</script>
