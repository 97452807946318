<template>
  <div class="body">
    <topnav></topnav>
    <div class="main-sec wf-section">
      <div class="_1200">
        <h1 class="inner-head">About</h1>
        <div data-duration-in="300" data-duration-out="100" data-current="Tab 4" data-easing="ease" class="main-tabs w-tabs">
          <div class="tabs-menu w-tab-menu">
            <div @click="tab = 0" data-w-tab="Tab 2" class="sidetab w-inline-block w-tab-link" :class="{ 'w--current': tab === 0 }">
              <div>About the firm</div>
            </div>
            <div @click="tab = 1" data-w-tab="Tab 4" class="sidetab w-inline-block w-tab-link" :class="{ 'w--current': tab === 1 }">
              <div class="text-block">Community</div>
            </div>
          </div>
          <div class="tabs-content w-tab-content">
            <div data-w-tab="Tab 2" class="w-tab-pane" :class="{ 'w--tab-active': tab === 0 }">
              <div v-html="about.body" class="long-txt"></div>
            </div>
            <div data-w-tab="Tab 4" class="w-clearfix w-tab-pane" :class="{ 'w--tab-active': tab === 1 }">
              <div v-html="community.body" class="long-txt"></div>
              <a href="https://www.israel21c.org/the-farmers-who-are-giving-their-land-a-years-rest/" target="_blank" class="article-link w-inline-block">
                <img src="../assets/612e332b1834262aa610588a_ben-rosenberg-1168x6572.jpg" loading="lazy" alt="" class="article-img" />
                <div class="article-txt-wrap">
                  <p class="article-link-head">
                    The farmers who are giving their land a year’s rest
                  </p>
                  <p class="article-small-txt">Featured on israel21c</p>
                  <p class="article-link-txt">Click to read more</p>
                </div>
              </a>
              <img src="../assets/5d5e2fbecfc85e4ebb6fc952_הטוב1.jpg" srcset="
                  https://assets.website-files.com/5d5e26eb7662017403f65924/5d5e2fbecfc85e4ebb6fc952_%D7%94%D7%98%D7%95%D7%911-p-500.jpeg  500w,
                  https://assets.website-files.com/5d5e26eb7662017403f65924/5d5e2fbecfc85e4ebb6fc952_%D7%94%D7%98%D7%95%D7%911-p-800.jpeg  800w,
                  https://assets.website-files.com/5d5e26eb7662017403f65924/5d5e2fbecfc85e4ebb6fc952_%D7%94%D7%98%D7%95%D7%911.jpg        1066w
                " sizes="(max-width: 767px) 21vw, (max-width: 991px) 14vw, 13vw" alt="" class="tov-logo" />
              <div class="div-block-3">
                <div class="slider inner w-dyn-list">
                  <carousel :autoplay="true" :nav="false" :loop="true" :dots="false" :slideTransition="'linear'" class="w-dyn-items">
                    <div role="listitem" class="collection-item-2 w-dyn-item" v-for="image in community.photoGallery" :key="image.imageURL">
                      <div :style="{
                        'background-image': `url(${image.imageURL})`,
                      }" class="slider-image" />
                    </div>
                  </carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import carousel from "sh-v-owl-carousel";

export default {
  data: () => ({
    tab: 1,
  }),
  components: {
    Topnav,
    Botfooter,
    carousel,
  },
  computed: {
    community() {
      return (
        this.$local.aboutPages?.find?.(({ title }) => title === "Community") ||
        {}
      );
    },
    about() {
      return (
        this.$local.aboutPages?.find?.(
          ({ title }) => title === "About the firm"
        ) || {}
      );
    },
  },
  name: "About",
};
</script>
