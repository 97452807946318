<template>
  <div
    data-collapse="medium"
    data-animation="over-right"
    data-duration="400"
    data-easing="ease"
    data-easing2="ease"
    role="banner"
    class="navbar w-nav"
  >
    <router-link to="/" class="brand w-nav-brand"
      ><img
        src="https://assets.website-files.com/5d5e26eb7662017403f65924/5d5e2fbe8983569254c60c20_logo.svg"
        alt="Stc"
        class="image"
    /></router-link>
    <nav
      role="navigation"
      class="nav-menu w-nav-menu"
      :class="{ 'open-nav': opennavbar }"
    >
      <div @click="opennavbar = false" class="close-side-menu"></div>
      <router-link to="/about" class="navlink w-nav-link">About</router-link>
      <div
        data-hover="true"
        data-delay="0"
        class="w-dropdown"
        @mouseover="navDrop = true"
        @mouseleave="navDrop = false"
      >
        <div class="navlink drop w-dropdown-toggle">
          <div class="icon w-icon-dropdown-toggle"></div>
          <div>Practice Areas</div>
        </div>
        <nav class="dropme w-dropdown-list" :class="{ 'w--open': navDrop }">
          <router-link
            v-for="area in $local.practiceAreas"
            :key="area._id"
            :to="`/practice-areas/${area.slug}`"
            class="dropdown-link w-dropdown-link"
            >{{ area.title }}</router-link
          >
        </nav>
      </div>
      <router-link to="/professionals" class="navlink w-nav-link"
        >Professionals</router-link
      ><router-link to="/charges-and-forms" class="navlink w-nav-link"
        >Charges and forms</router-link
      ><router-link to="/contact" class="navlink w-nav-link"
        >Contact</router-link
      >
    </nav>
    <div class="menu-button w-nav-button" @click="opennavbar = true">
      <div class="w-icon-nav-menu"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "topnav",
  data: () => ({
    navDrop: false,
    opennavbar: false,
  }),
};
</script>

<style>
@media screen and (max-width: 991px) {
  .nav-menu.w-nav-menu.open-nav {
    position: fixed;
    z-index: 9999;
    right: 0;
    top: 0;
    display: block;
    height: 100vh;
    padding: 20px 0;
  }
  .navlink {
    display: block;
  }
  .nav-menu .w-dropdown {
    width: 100%;
  }
}
</style>
