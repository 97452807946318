<template>
  <div class="body">
    <topnav></topnav>
    <div class="main-sec wf-section">
      <div class="_1200">
        <h1 class="inner-head">Contact</h1>
        <div
          data-duration-in="300"
          data-duration-out="100"
          data-current="Tab 2"
          data-easing="ease"
          class="main-tabs w-tabs"
        >
          <div class="tabs-menu w-tab-menu">
            <div
              @click="tab = 0"
              class="sidetab long w-inline-block w-tab-link"
              :class="{ 'w--current': tab === 0 }"
            >
              <div>Office location</div>
            </div>
            <div
              @click="tab = 1"
              class="sidetab long w-inline-block w-tab-link"
              :class="{ 'w--current': tab === 1 }"
            >
              <div class="text-block">Contact us</div>
            </div>
          </div>
          <div class="tabs-content w-tab-content">
            <div
              data-w-tab="Tab 2"
              class="w-tab-pane"
              :class="{ 'w--tab-active': tab === 0 }"
            >
              <div class="_990 inner">
                <h5 class="loc-head inner hide"></h5>
                <div class="columns hide w-row">
                  <div class="col-1 w-col w-col-4 w-col-stack">
                    <p class="loc-info"></p>
                  </div>
                  <div class="w-col w-col-4 w-col-stack">
                    <p class="loc-info"></p>
                  </div>
                  <div class="w-col w-col-4 w-col-stack">
                    <p class="loc-info"></p>
                    <img
                      src="https://assets.website-files.com/5d5e26eb7662017403f65924/5d5e2fbe766201feadf67a90_waze.svg"
                      alt
                      class="waze"
                    />
                    <p class="loc-info waze">
                      <a
                        href="https://www.waze.com/ul?ll=32.07530590%2C34.78396230&amp;navigate=yes"
                        target="_blank"
                        class="black-link"
                        >Waze navigation</a
                      >
                    </p>
                  </div>
                </div>
                <div v-for="office in $local.offices" :key="office._id">
                  <div class="light-green-line"></div>

                  <h5 class="loc-head">{{ office.title }}</h5>
                  <div class="w-row">
                    <div class="col-1 w-col w-col-4 w-col-stack">
                      <p class="loc-info">
                        <span class="inner-icon"></span>{{ office.address }}
                      </p>
                    </div>
                    <div class="w-col w-col-4 w-col-stack">
                      <p class="loc-info">
                        <span class="inner-icon"></span
                        ><a :href="`tel:${office.phone}`" class="tran-link"
                          >{{ office.phone }}‍</a
                        ><br />
                        <span class="inner-icon"></span
                        ><span class="tran-link">{{ office.fax }}<br /></span>
                      </p>
                    </div>
                    <div class="w-col w-col-4 w-col-stack">
                      <p class="loc-info">
                        <span class="inner-icon mail"></span
                        ><a
                          :href="`mailto:${office.email}`"
                          class="tran-link"
                          >{{ office.email }}</a
                        ><br /><span class="inner-icon mail"></span
                        ><a
                          v-if="office.googleMaps"
                          :href="office.googleMaps"
                          target="_blank"
                          class="black-link"
                          >View map</a
                        >
                      </p>
                      <img
                        src="https://assets.website-files.com/5d5e26eb7662017403f65924/5d5e2fbe766201feadf67a90_waze.svg"
                        alt
                        class="waze"
                      />
                      <p class="loc-info waze">
                        <a
                          v-if="office.wazeLink"
                          :href="office.wazeLink"
                          target="_blank"
                          class="black-link"
                          >Waze navigation</a
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-w-tab="Tab 4"
              class="w-tab-pane"
              :class="{ 'w--tab-active': tab === 1 }"
            >
              <div class="form-block inner w-form">
                <form
                  @submit="submitForm"
                  action="/success"
                  method="post"
                  data-netlify="true"
                  class="w-clearfix"
                >
                  <input
                    type="text"
                    class="field _50p w-input"
                    maxlength="256"
                    name="name"
                    data-name="Name"
                    placeholder="Name"
                    id="name"
                    v-model="form.name"
                  />
                  <input
                    type="tel"
                    class="field _50p right w-input"
                    maxlength="256"
                    name="Phone"
                    data-name="Phone"
                    placeholder="Phone number"
                    id="Phone"
                    v-model="form.phone"
                  />
                  <input
                    type="email"
                    class="field w-input"
                    maxlength="256"
                    name="email"
                    data-name="Email"
                    placeholder="Email"
                    id="email"
                    required
                    v-model="form.email"
                  />
                  <textarea
                    id="Message"
                    name="Message"
                    maxlength="5000"
                    data-name="Message"
                    placeholder="Message"
                    class="field big w-input"
                    v-model="form.message"
                  ></textarea>
                  <input
                    type="submit"
                    data-wait="Please wait..."
                    value="Send"
                    class="submit-button w-button"
                  />
                </form>
                <div
                  class="success-message w-form-done"
                  v-if="formStatus === 'success'"
                >
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div class="w-form-fail" v-else-if="formStatus === 'err'">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <botfooter></botfooter>
  </div>
</template>
<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
export default {
  components: {
    Topnav,
    Botfooter,
  },
  data() {
    return {
      tab: 0,

      form: {
        name: "",
        phone: "",
        email: "",
        message: "",
      },
      formStatus: "",
      submitting: false,
    };
  },
  methods: {
    async submitForm() {
      this.formStatus = "";

      if (this.submitting) return;
      this.submitting = true;
      try {
        await this.$db.collection("contact").post(this.form);
        this.formStatus = "success";
      } catch (err) {
        this.formStatus = "err";
      }
      this.submitting = false;
    },
  },
  name: "Contact",
};
</script>
