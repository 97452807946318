<template>
  <div class="body">
    <topnav></topnav>
    <div class="main-sec flex wf-section">
      <div class="_1200">
        <div class="tnx">
          Thank you!<br />Your submission has been received!
        </div>
        <div class="center">
          <a href="javascript:history.back();" class="btn w-button">Back</a>
        </div>
      </div>
    </div>
    <botfooter></botfooter>
  </div>
</template>
<script>
  import Botfooter from "@/components/Botfooter.vue";
  import Topnav from "@/components/Topnav.vue";
  export default {
    components: {
      Topnav,
      Botfooter,
    },
    name: "success",
  };
</script>
