import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import ChargesForms from '../views/Charges-and-forms.vue'
import Contact from '../views/Contact.vue'
import Terms from '../views/Terms-of-use.vue'
import Professionals from '../views/Professionals'
import Success from '../views/Success.vue'
import PracticeAreas from '../views/Practice-areas'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/charges-and-forms',
    name: 'charges-and-forms',
    component: ChargesForms
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/terms-of-use',
    name: 'terms',
    component: Terms
  },
  {
    path: '/professionals',
    name: 'professionals',
    component: Professionals
  },
  {
    path: '/success',
    name: 'success',
    component: Success
  },
  {
    path: '/Practice-areas/:slug',
    name: 'practiceAreas',
    component: PracticeAreas,
  },
]


const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
