<template>
  <div class="body">
    <topnav></topnav>
    <router-link to="/" class="big-close w-inline-block">
      <img
        src="https://assets.website-files.com/5d5e26eb7662017403f65924/5d5e2fbecfc85ec0266fc950_close.svg"
        class="close-image"
      />
    </router-link>
    <div class="main-sec wf-section">
      <div class="_650">
        <h1 class="inner-head full">Terms of use</h1>
        <div class="w-richtext">
          <p><strong>General</strong></p>
          <p>
            These Terms of Use set forth herein apply to the stc.co.il web site
            owned and operated by Sanford T. Colb &amp; Co. (the “Web Site”).
          </p>
          <p><br /></p>
          <p>
            PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY BEFORE USING
            THIS WEB SITE.
          </p>
          <p><br /></p>
          <p>
            By using this Web Site you signify your assent to these terms of
            use. If you do not agree to these terms of use, please do not use
            the site. We reserve the right, at our discretion, to change,
            modify, add, or remove portions of these terms at any time. Please
            check these terms periodically for changes. Your continued use of
            this Web Site following the posting of changes to these terms will
            mean you accept those changes.
          </p>
          <p><br /></p>
          <p><strong>Restrictions on use of materials</strong></p>
          <p>
            Unless otherwise noted, all materials, including images,
            illustrations, designs, icons, photographs, video clips, written and
            other materials that are part of this Web Site are copyrights,
            trademarks and/or other intellectual properties owned, controlled or
            licensed by Sanford T. Colb &amp; Co. (&quot;STC&quot;). No material
            from this Web Site may be copied, reproduced, republished, uploaded,
            posted, transmitted, or distributed in any way, provided, however,
            you may download, where specifically permitted, one copy of the
            materials on any single computer for your personal, non-commercial
            use only, provided you keep intact all copyright and other
            proprietary notices.
          </p>
          <p><br /></p>
          <p>
            Modification of the materials or use of the materials for any other
            purpose is a violation of STC&#x27;s copyright and other proprietary
            rights. For purposes of these terms, the use of any such material on
            any other Web Site or networked computer environment is prohibited.
            All trademarks, service marks, and trade names are proprietary to
            STC.
          </p>
          <p><br /></p>
          <p>Our privacy statement.</p>
          <p><br /></p>
          <p>
            It is our policy to respect the privacy of our guests. Therefore, in
            general, we will not monitor, edit, or disclose the contents of a
            guest&#x27;s e-mail unless required in the course of normal
            maintenance of this Web Site and its systems or unless required to
            do so by law or in the good-faith belief that such action is
            necessary to: (1) comply with the law or comply with legal process
            served on STC; (2) protect and defend the legitimate business
            interests, rights or property of STC, it&#x27;s users, customers or
            affiliates; or (3) act in an emergency to protect the personal
            safety of our guests or the public. Guests shall remain solely
            responsible for the content of their messages.<br />
          </p>
        </div>
      </div>
    </div>
    <botfooter></botfooter>
  </div>
</template>
<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
export default {
  components: {
    Topnav,
    Botfooter,
  },
  name: "Terms",
};
</script>
