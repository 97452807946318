import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import carousel from "sh-v-owl-carousel";
import router from './router'
import data from '../localData.json'
import Bagel from '@bageldb/bagel-db';
const db = new Bagel('eyJhbGciOiJSUzI1NiIsImtpZCI6ImFwaS1rZXkiLCJ0eXAiOiJKV1QifQ.eyJvcmdhbml6YXRpb25JRCI6ImJ2b3JnY2EyM2FrZzAwOHQwYnBnIiwicHJvamVjdElEIjoiY2ZicjdwcTIzYWtzNzNiOHFqdDAiLCJhdWQiOiJodHRwczovL2FwcC5iYWdlbGRiLmNvbSIsImp0aSI6ImFiNzRlNTUyLTA0M2UtNDY1ZS1hNjNjLTU2OGRjNmU0Mzg0MSIsImlzcyI6Imh0dHBzOi8vYXV0aGVudGljYXRpb24uYmFnZWxkYi5jbyIsInN1YiI6ImNmYnI3cHEyM2FrczczYjhxanQwIn0.idf-GBFRdQ5py8qR1nzdl9cAu6e4crTVwk2wum1NgLSYtg9pWnoE5CrZ_T3yVFoobhMMQDR3KUOGKZeLJ81Mzb3AJbWAl8_fZ9_nofId5aukJo_YYwPDZLEo35qljcqBE8XKnqY0iH12ARWcAI6sIHzQPNS7A86oGuzkeTwVFoQZQRhxnH_NaL2VEChpa9HwW9WalNva1g9KSjskM1y0N46fDOkLQfkDFLlwjE30iAVrVMmnzLLXNFXGZH0YgPUv_L4vhIS9oT9n2UOZubnZvTGWf5_e3z5vXP9ra9SjlYHGXWk1AQGIutmfpvBDBVjRKLR8NRh8L1kZbjrUOL08EwrFT_PwSt_WrrNA1UqkPUOKRd9XHku5gBEvSSUCEyt0sIi7TV3RNU4SAyDHv6Ms-Y-MeKJAYc-oekuYw79SUS7RfTGeYM1LK17X0EC92bjPd7Xp3hT_JEdc_AFy4htn6lnZvmN_cxk8GKpNkluYygT6GE4lrs1AxR6-3ybiGksTbDqIrXiySu-jTAK5P_KT97N1ipIpLoemZWpnGqbqou_1BSU3_hZlWh-CCGggnIPVy-RbCyU7mArI9w_appVkpaugeyl3BCL_1EV6vzky4GOPIgpxU2mBGWGY2uiaR1CtZ93lGwmA-0g5VAakTsfh-EDrM6FGafkoiO7bnDeLD1k')


Vue.component('carousel',carousel)
Vue.use(VueRouter)

Vue.config.productionTip = false
Vue.prototype.$local = data
Vue.prototype.$db = db

new Vue({
  router,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')