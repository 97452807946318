<template>
  <div class="body">
    <topnav></topnav>
    <div class="popup" :class="{ show: popup }" @click="popup = false">
      <div class="popup-box" @click.stop>
        <span @click="popup = false" class="mid-close w-inline-block"
          ><img
            src="https://assets.website-files.com/5d5e26eb7662017403f65924/5d5e2fbecfc85ec0266fc950_close.svg"
            alt
        /></span>
        <div class="scroll">
          <div class="frame" v-if="popup">
            <p class="big-name">{{ this[popup][popupIndex].name }}</p>
            <div
              class="bio-rtf w-richtext"
              v-html="this[popup][popupIndex].about"
            />
          </div>
        </div>
        <a
          v-if="popup"
          v-show="popupIndex > 0"
          @click="selectPopup(popupIndex - 1, popup)"
          class="back w-inline-block"
        >
          <img
            src="../assets/5d5e2fbecfc85e5fdf6fc94f_left.svg"
            alt
            class="image-3"
          />
        </a>
        <a
          v-if="popup"
          v-show="popupIndex < this[popup].length - 1"
          @click="selectPopup(popupIndex + 1, popup)"
          class="next w-inline-block"
        >
          <!--  -->
          <img
            src="../assets/5d5e2fbe89835603dbc60c1f_right.svg"
            alt
            class="image-4"
          />
        </a>
      </div>
    </div>
    <div class="main-sec wf-section">
      <div class="_1200">
        <h1 class="inner-head">Professionals</h1>
        <div
          data-duration-in="300"
          data-duration-out="100"
          data-current="Tab 2"
          data-easing="ease"
          class="main-tabs w-tabs"
        >
          <div class="tabs-menu line w-tab-menu">
            <div
              @click="tab = 0"
              class="sidetab long w-inline-block w-tab-link"
              :class="{ 'w--current': tab === 0 }"
            >
              <div>Attorneys &amp; Patent Attorneys</div>
            </div>
            <div
              @click="tab = 1"
              class="sidetab long w-inline-block w-tab-link"
              :class="{ 'w--current': tab === 1 }"
            >
              <div>Trainees</div>
            </div>
          </div>
          <div class="tabs-content w-tab-content">
            <div
              data-w-tab="Tab 2"
              class="w-tab-pane"
              :class="{ 'w--tab-active': tab === 0 }"
            >
              <div class="w-dyn-list">
                <div role="list" class="team-list w-dyn-items">
                  <div
                    role="listitem"
                    class="collection-item-team w-dyn-item"
                    v-for="(member, i) of members"
                    :key="member._id"
                    @click="selectPopup(i, 'members')"
                  >
                    <div
                      class="team-item w-inline-block"
                      :class="{ vip: member.category.value === 'Leadership' }"
                    >
                      <h5 class="name">{{ member.name }}</h5>

                      <p
                        class="loc-info inner"
                        v-for="office in member.office"
                        :key="office.itemRefID"
                      >
                        <span class="inner-icon black"></span
                        >{{ office.value }}
                      </p>

                      <div class="info-box">
                        <p class="font-icon"><br /></p>
                        <p class="info">{{ member.phone }}</p>
                      </div>
                      <div class="info-box">
                        <p class="font-icon"><br /></p>
                        <p class="info email">{{ member.email }}</p>
                      </div>
                    </div>
                    <a
                      href="mr-sanford-t-colb.html"
                      class="team-item w-inline-block w-condition-invisible"
                    >
                      <h5 class="name">Mr. Sanford T. Colb</h5>
                      <p class="loc-info inner tv w-condition-invisible">
                        <span class="inner-icon black"></span>Tel Aviv Office
                      </p>
                      <p class="loc-info inner">
                        <span class="inner-icon black"></span>Rehovot office
                      </p>
                      <p class="loc-info inner jer w-condition-invisible">
                        <span class="inner-icon black"></span>Jerusalem office
                      </p>
                      <div class="info-box">
                        <p class="font-icon"><br /></p>
                        <p class="info">+972-8-9455122</p>
                      </div>
                      <div class="info-box">
                        <p class="font-icon"><br /></p>
                        <p class="info email">stcdesk@stc.co.il</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-w-tab="Tab 4"
              class="w-tab-pane"
              :class="{ 'w--tab-active': tab === 1 }"
            >
              <div class="w-dyn-list">
                <div role="list" class="team-list w-dyn-items">
                  <div
                    role="listitem"
                    class="collection-item-team w-dyn-item"
                    v-for="(member, i) in trainees"
                    :key="member._id"
                    @click="selectPopup(i, 'trainees')"
                  >
                    <div
                      class="team-item w-inline-block"
                      :class="{ vip: member.category.value === 'Leadership' }"
                    >
                      <h5 class="name">{{ member.name }}</h5>

                      <p
                        class="loc-info inner"
                        v-for="office in member.office"
                        :key="office.itemRefID"
                      >
                        <span class="inner-icon black"></span
                        >{{ office.value }}
                      </p>

                      <div class="info-box">
                        <p class="font-icon"><br /></p>
                        <p class="info">{{ member.phone }}</p>
                      </div>
                      <div class="info-box">
                        <p class="font-icon"><br /></p>
                        <p class="info email">{{ member.email }}</p>
                      </div>
                    </div>
                    <a
                      href="mr-sanford-t-colb.html"
                      class="team-item w-inline-block w-condition-invisible"
                    >
                      <h5 class="name">Mr. Sanford T. Colb</h5>
                      <p class="loc-info inner tv w-condition-invisible">
                        <span class="inner-icon black"></span>Tel Aviv Office
                      </p>
                      <p class="loc-info inner">
                        <span class="inner-icon black"></span>Rehovot office
                      </p>
                      <p class="loc-info inner jer w-condition-invisible">
                        <span class="inner-icon black"></span>Jerusalem office
                      </p>
                      <div class="info-box">
                        <p class="font-icon"><br /></p>
                        <p class="info">+972-8-9455122</p>
                      </div>
                      <div class="info-box">
                        <p class="font-icon"><br /></p>
                        <p class="info email">stcdesk@stc.co.il</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <botfooter></botfooter>
  </div>
</template>
<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
export default {
  data: () => ({
    tab: 0,
    popupIndex: 0,
    popup: false,
  }),
  components: {
    Topnav,
    Botfooter,
  },
  methods: {
    selectPopup(index, collection) {
      this.popupIndex = index;
      this.popup = collection;
    },
  },
  computed: {
    members() {
      return this.$local.professionals.filter(
        (m) => m.category.value != "Trainees"
      );
    },
    trainees() {
      return this.$local.professionals.filter(
        (m) => m.category.value === "Trainees"
      );
    },
    canGoToNextPopup() {
      return !(this.popupIndex >= this.members?.length - 1);
    },
  },
  name: "Professionals",
};
</script>
