<template>
  <div class="body">
    <topnav></topnav>
    <div class="main-sec wf-section">
      <div class="_1200 w-clearfix">
        <h1 class="inner-head notabs">Charges and forms</h1>
        <div class="txt-side">
          <p class="long-txt no-tabs">
            Click on any of the following links to open or download a file:<br />
          </p>
          <h5 class="small-head">Our Charges</h5>
          <div class="w-dyn-list">
            <div role="list" class="w-dyn-items">
              <div
                v-for="file in charges"
                :key="file._id"
                role="listitem"
                class="collection-item w-dyn-item"
              >
                <a
                  :href="file.files.imageURL + '&download=true'"
                  target="_blank"
                  class="w-inline-block"
                >
                  <div class="div-block"></div>
                  <p class="black-link">{{ file.title }}</p>
                  <p class="downloadme"></p>
                </a>
              </div>
            </div>
          </div>
          <h5 class="small-head">Forms</h5>
          <div class="w-dyn-list">
            <div
              v-for="file in $local.chargesAndForms.filter(
                (m) => m.type.value === 'Forms'
              )"
              :key="file._id"
              role="listitem"
              class="collection-item w-dyn-item"
            >
              <a
                :href="file.files.imageURL + '&download=true'"
                target="_blank"
                class="w-inline-block"
                :download="
                  (file.files.altText || file.title) + file.files.extension
                "
              >
                <div class="div-block"></div>
                <p class="black-link">{{ file.title }}</p>
                <p class="downloadme"></p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <botfooter></botfooter>
  </div>
</template>
<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
export default {
  components: {
    Topnav,
    Botfooter,
  },
  computed: {
    charges() {
      return this.$local.chargesAndForms.filter((m) => m.type.value != "Forms");
    },
  },
  name: "Charges-and-forms",
};
</script>
