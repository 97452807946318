<template>
  <div class="body">
    <topnav></topnav>
    <header class="hero wf-section">
      <h1 class="main-head">
        {{ $local.homePage?.title }}
      </h1>
      <h2 class="heading">Intellectual Property Law</h2>
      <div class="green-line"></div>
      <div v-html="$local.homePage?.description" class="open-pera"></div>
    </header>
    <div class="slider w-dyn-list">
      <carousel
        :autoplay="true"
        :nav="false"
        :margin="10"
        :dots="false"
        :responsive="{ 0: { items: 1 }, 600: { items: 3 } }"
      >
        <div
          v-for="slide in $local.homePage?.photoGallery || []"
          :key="slide._id"
          role="list"
        >
          <div role="listitem" class="collection-item-2 w-dyn-item">
            <div
              :style="`background-image: url(${slide.imageURL})`"
              class="slider-image"
            ></div>
          </div>
        </div>
      </carousel>
    </div>

    <div class="loc-sec wf-section">
      <div class="_1200">
        <div class="_990 _100-left">
          <div
            class="w-row"
            v-for="location in $local.offices"
            :key="location._id"
          >
            <h5 class="loc-head">{{ location.title }}</h5>
            <div class="col-1 w-col w-col-3 w-col-medium-6">
              <p class="loc-info">
                <span class="inner-icon"></span>
                <span v-html="location.address" />
              </p>
            </div>
            <div class="w-col w-col-3 w-col-medium-6">
              <p class="loc-info">
                <span class="inner-icon"></span
                ><a href="tel:+972-3-7180800" class="tran-link"
                  >{{ location.phone }}<br />‍</a
                ><span class="inner-icon"></span
                ><span class="tran-link">{{ location.fax }}<br /></span>
              </p>
            </div>
            <div class="w-col w-col-3 w-col-medium-6">
              <p class="loc-info">
                <span class="inner-icon mail"></span
                ><a href="mailto:colbpat@stc.co.il" class="tran-link">{{
                  location.email
                }}</a
                ><br /><span class="inner-icon mail"></span
                ><a
                  v-if="location.googleMaps"
                  :href="location.googleMaps"
                  target="_blank"
                  class="black-link"
                  >View map</a
                >
              </p>
            </div>
            <div v-if="location.wazeLink" class="w-col w-col-3 w-col-medium-6">
              <img
                src="../assets/5d5e2fbe766201feadf67a90_waze.svg"
                alt=""
                class="waze"
              />
              <p class="loc-info waze">
                <a :href="location.wazeLink" target="_blank" class="black-link"
                  >Waze navigation</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="what-new-sec wf-section">
      <div class="_1200">
        <h3 class="sec-head light">What’s new</h3>
        <div class="collection-list-wrapper w-dyn-list">
          <div role="list" class="collection-list w-clearfix w-dyn-items">
            <div
              role="listitem"
              class="white-box w-dyn-item"
              v-for="item in $local.whatsNew
                .filter((w) => w.featureOnHomepage)
                .slice(0, 3)"
              :key="item._id"
            >
              <h6 class="news-box-head">
                {{ item.title }}
              </h6>
              <div v-html="item.body" class="news-txt enddots"></div>
              <a @click="popup = item" class="popuplink w-inline-block">
                <p class="read-more">Read More</p>
              </a>
              <div
                @click="popup = false"
                class="popup"
                :class="{ show: popup }"
              >
                <div @click.stop class="popup-box">
                  <a @click="popup = false" class="mid-close w-inline-block"
                    ><img
                      src="../assets/5d5e2fbecfc85ec0266fc950_close.svg"
                      alt=""
                  /></a>
                  <div class="scroll" v-if="popup">
                    <h3 class="news-box-head" style="font-size: 34px">
                      {{ popup.title }}
                    </h3>
                    <div class="w-richtext" v-html="popup.body"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="what-new-movie w-clearfix">
          <div class="mid-mov-wrap">
            <div class="w-embed"></div>
            <div
              style="padding-top: 56.17021276595745%"
              class="vid w-video w-embed"
            >
              <iframe
                class="embedly-embed"
                src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FyTBZVzNZX68%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DyTBZVzNZX68&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FyTBZVzNZX68%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube"
                scrolling="no"
                title="YouTube embed"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen="true"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cont-sec wf-section">
      <div class="form-block w-form">
        <form
          @submit.prevent="submitForm"
          v-if="formStatus !== 'success'"
          class="w-clearfix"
        >
          <h3 class="sec-head"><strong>Contact us</strong></h3>
          <input
            type="text"
            class="field _50p w-input"
            maxlength="256"
            name="name"
            data-name="Name"
            placeholder="Name"
            id="name"
            v-model="form.name"
          />
          <input
            type="tel"
            class="field _50p right w-input"
            maxlength="256"
            name="Phone"
            data-name="Phone"
            placeholder="Phone number"
            id="Phone"
            v-model="form.phone"
          />
          <input
            type="email"
            class="field w-input"
            maxlength="256"
            name="email"
            data-name="Email"
            placeholder="Email"
            id="email"
            v-model="form.email"
            required=""
          />
          <textarea
            id="Message"
            name="Message"
            maxlength="5000"
            data-name="Message"
            placeholder="Message"
            class="field big w-input"
            v-model="form.message"
          ></textarea>
          <input
            type="submit"
            data-wait="Please wait..."
            value="Send"
            class="submit-button w-button"
          />
        </form>
        <div class="w-form-done" v-if="formStatus === 'success'">
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div class="w-form-fail" v-else-if="formStatus === 'err'">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>
    </div>
    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import carousel from "sh-v-owl-carousel";

export default {
  components: {
    Topnav,
    Botfooter,
    carousel,
  },
  data() {
    return {
      popup: false,
      form: {
        name: "",
        phone: "",
        email: "",
        message: "",
      },
      formStatus: "",
      submitting: false,
    };
  },
  methods: {
    async submitForm() {
      this.formStatus = "";

      if (this.submitting) return;
      this.submitting = true;
      try {
        await this.$db.collection("contact").post(this.form);
        this.formStatus = "success";
      } catch (err) {
        this.formStatus = "err";
      }
      this.submitting = false;
    },
  },
  name: "Home",
};
</script>
<style>
@media only screen and (max-width: 1280px) and (min-width: 991px) {
  .vid {
    height: 45vw;
  }
}
</style>
